import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { toast } from "react-toastify";
import { cloudApplicationUrl, cloudServiceUrl, serviceUrl } from "../apiConfig";
import { clearStore, getItem, setItem } from "./token";

// Email regex pattern
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// US Phone number regex pattern
const usPhoneRegex = /^(\+1[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;

// Custom hook for email validation
export const useEmailValidation = () => {
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email: string) => {
    if (!emailRegex.test(email.trim()) && email) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  return { emailError, validateEmail };
};

// Custom hook for phone validation
export const usePhoneValidation = () => {
  const [phoneError, setPhoneError] = useState("");

  const validatePhone = (phone: string) => {
    if (phone && !usPhoneRegex.test(phone)) {
      setPhoneError("Please enter a valid US phone number.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  return { phoneError, validatePhone };
};

export const usePasswordValidation = () => {
  const [passwordError, setPasswordError] = useState("");

  const validatePassword = (password: string) => {
    if (password.trim()) {
      console.log(password, "password");
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const uppercaseRegex = /[A-Z]/;
      if (
        password.trim().length < 8 ||
        !specialCharRegex.test(password) ||
        !uppercaseRegex.test(password)
      ) {
        setPasswordError(
          "Password must be at least 8 characters long, contain at least one special character, and one uppercase letter."
        );
        return false;
      } else {
        console.log("password is empty");
        setPasswordError("");
        return true;
      }
    }
  };

  return { passwordError, validatePassword, setPasswordError };
};

export const formatPhoneNumberToInternational = (phoneNumber: string) => {
  // Remove non-numeric characters
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Prepend '+1' for U.S. numbers
  const internationalNumber = `${cleanedNumber}`;

  return internationalNumber;
};

export const convertToUSANumber = (internationalNumber: string) => {
  // Remove the "+1" country code if it exists
  const cleanedNumber = internationalNumber?.replace(/^\+1/, "");

  // Check if the number is exactly 10 digits
  if (cleanedNumber?.length === 10) {
    const areaCode = cleanedNumber.slice(0, 3);
    const firstPart = cleanedNumber.slice(3, 6);
    const secondPart = cleanedNumber.slice(6);

    console.log(
      `(${areaCode}) ${firstPart}-${secondPart}`,
      "convertToUSANumber"
    );

    // Format the number in the desired USA format
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Return an error or original number if it doesn't match the expected format
  return "";
};

export const getFullName = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return "-";
};

export const generateScaledScore = (
  englishScore: number,
  mathScore: number
) => {
  const mathematicsscaledscore = Math.round((mathScore / 44) * 600 + 200);
  const readingandwritingscaledscore = Math.round(
    (englishScore / 54) * 600 + 200
  );
  const totalscaledscore =
    mathematicsscaledscore + readingandwritingscaledscore;

  return {
    mathematicsscaledscore,
    readingandwritingscaledscore,
    totalscaledscore,
  };
};

export const loginToCloudService = async () => {
  try {
    const url = `${cloudServiceUrl}auth/get/token`;
    const payload = {
      userName: "aWN0c3lzdGVtc0BtYWlsaW5hdG9yLmNvbQ==",
      password: "MTIzNDU2Nzg5",
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        applicationurl: cloudApplicationUrl,
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const data = await response.json();
      localStorage.setItem("cloudToken", data?.data);
      return data?.data;
    }
  } catch (err) {
    toast.error("cloud Service login failed");
    console.error(err);
  }
};

export const GetFile = async (
  file_name: any,
  options: { signal?: AbortSignal } = {}
) => {
  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if (tokenExpired) {
    cloudServiceToken = await loginToCloudService();
  }

  try {
    const url = `${cloudServiceUrl}s3/generate/presigned/url`;
    let payload = {
      objectKey: file_name,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          cloudServiceToken || localStorage.getItem("cloudToken")
        }`,
        applicationurl: cloudApplicationUrl,
      },
      body: JSON.stringify(payload),
      signal: options.signal,
    });
    if (response.status === 200) {
      const data = await response.json();
      return data?.data;
    }
    return "";
  } catch (error) {
    console.error("Error fetching data:", error);
    return "";
  }
};

export const cloudTokenExpiry = () => {
  let cloudToken = localStorage.getItem("cloudToken");
  let decodedToken = null;

  if (cloudToken) {
    decodedToken = jwtDecode(cloudToken);
  } else {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const tokenExpired =
    decodedToken?.exp !== undefined ? decodedToken.exp < currentTime : true;
  return tokenExpired;
};

export const formatDateForWidgets = (dateString: string): string => {
  const date = new Date(dateString);

  const day = date.getDate();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase();

  return `${day}${suffix} ${month}`;
}

export const getApiUrlToHandleError = () => {
  return ['api/test/retrievetest','api/test/starttests/v1','api/test/endtest','api/test/savetest','api/test/userresults','api/county/getall','api/countyschools','api/profile/student','api/profile/createparent','api/profile/parent','api/profile/createstudent','api/test/getresumetest','api/test/calculate/scaledscore','usertest/processactivity','api/test/userresult/byid'];
}

export const getTokenExpiry = (): boolean => {
  const token = getItem('token');
  if (token) {
    try {
      const decoded: { exp?: number } = jwtDecode(token);
      if (decoded.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        return (decoded.exp - 300) < currentTime;
      }
    } catch (error) {
      console.error('Invalid token', error);
    }
  }
  return true;
};

let getRefreshTokenApiCalled:boolean = false;
let refreshTokenPromise: Promise<string> | null = null;

const getNewToken = async () => {
  getRefreshTokenApiCalled = true;
  const refreshToken = getItem("refreshToken");
  if (refreshToken) {
    for (let attempt = 1; attempt <= 3; attempt++) {
      try {
        const response = await fetch(
          `${serviceUrl}/user/generatetoken?refreshToken=${refreshToken}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getItem("token")}`,
            },
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          const newToken = data?.data?.token;
          if (newToken) {
            setItem("token", newToken);
            return newToken;
          }
        } else {
          console.error(
            `Attempt ${attempt} failed with status: ${response.status}`
          );
        }
      } catch (error) {
        console.error(`Attempt ${attempt} encountered an error:`, error);
      }
      if (attempt === 3) {
        navigateToLogin();
      }
    }
  }
};

const navigateToLogin = () => {
  console.error("Failed to refresh token after 3 attempts");
  alert("Your session has expired. Please log in again.");
  clearStore();
  window.location.href = "/login";
};

export const getMemoizedRefreshToken = async (): Promise<string> => {
  if (!getRefreshTokenApiCalled || !refreshTokenPromise) {
    refreshTokenPromise = getNewToken()
      .finally(() => {
        setTimeout(() => {
          refreshTokenPromise = null;
          getRefreshTokenApiCalled = false;
        }, 10000); 
    });
  }
  return refreshTokenPromise;
};