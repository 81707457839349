import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import {
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setIsModuleCompleted,
  setIsPopUp,
  setQuestionId,
  setReviewPageClicked,
  setSpentTime,
} from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { next } from "../../utils/commonLogic";
import { getItem } from "../../utils/token";
import useSendTestActivity, {
  ActivityData,
} from "../customHook/useUserTestActivity";

const MathModuleOne = () => {
  const dispatch = useDispatch();
  const { currentTime, testId, userTestId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
  const {
    mathModuleOneQuestions,
    count,
    mathsModuleOneAnswers,
    reviewPageClicked,
  } = useSelector((state: RootState) => ({
    mathModuleOneQuestions: state.root.startFullTest.mathModuleOneQuestions,
    count: state.root.startFullTest.count,
    mathsModuleOneAnswers: state.root.resultsSlice.mathsModuleOneAnswers,
    reviewPageClicked: state.root.startFullTest.reviewPageClicked,
  }));
  const isPreview = getItem("testType") === "PREVIEW";
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const sendTestActivity = useSendTestActivity();
    useEffect(() => {
      setStartTime(new Date());
    }, [count]);
    const saveHandler = () => {
        // dispatch(openDialog("mathsInstructions"));
      };
    
      const heading = useCallback(() => {
        return (
          <div className="flex flex-col">
            <div className="text-base font-semibold">
              {isPreview
                ? "Section 2: Math Questions"
                : "Section 2,Module 1: Math Questions"}
            </div>
            {/* <div className="cursor-pointer">Directions </div> */}
          </div>
        );
      },[]);
      const timer = () => {
        return <div>9:25</div>;
      };
      const exit = () => {
        return (
          <div>
            <CustomButton
              name="Save & Exit"
              onClickHandler={saveHandler}
              bg="bg-white"
              color="[#333]"
              font="font-bold"
              width="w-full"
              border="border-[#333]"
              py="py-2"
              round="full"
            />
          </div>
        );
      };
      const children = () => {
        return <div className="flex justify-center items-center">Maths Module ONE Children Component </div>;
      };
      const textName = () => {
        return <div className="font-semibold">  {getItem('currentTestName')} </div>;
      };
      let moduleCompleteTimeout: NodeJS.Timeout | null = null;
      let mathModuleTimeOut: NodeJS.Timeout | null = null;
      const nextClickHandler = () => {
        if (!startTime || isNextDisabled) return;
        setIsNextDisabled(true);
        const endTime = new Date();
        dispatch(setReviewPageClicked(false));
        if (count > mathModuleOneQuestions.length - 1 || reviewPageClicked) {
          dispatch(setIsModuleCompleted(true));
          moduleCompleteTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleComplete"));
          }, 500);
          if (getItem("testType") !== "PREVIEW") {
            mathModuleTimeOut = setTimeout(() => {
              dispatch(changeCurrentModuleName("mathModule2"));
              dispatch(setCurrentTime(2100));
            }, 7000);
          } else {
            moduleCompleteTimeout = setTimeout(() => {
              dispatch(changeCurrentModuleName("moduleComplete"));
            }, 500);
            mathModuleTimeOut = setTimeout(() => {
              dispatch(changeCurrentModuleName("testCompleted"));
            }, 7000);
          }
          dispatch(
            setSpentTime({
              field: "mathModuleOneSpentTime",
              value: +currentTime,
            })
          );
          dispatch(setCount(""));
        } else {
          setStartTime(endTime);
          dispatch(setCount("Increment"));
          if (!isPreview) {
            const activityData: ActivityData = {
              testId: userTestId,
              startTime,
              endTime,
              module: 301,
              section: 701,
              fromQuestion: count + 1,
              toQuestion:
                count + 1 === mathModuleOneQuestions.length
                  ? count + 1
                  : count + 2,
              event: 501,
              questionId: mathModuleOneQuestions[count]._id,
            };
            sendTestActivity(activityData);
            dispatch(setQuestionId(mathModuleOneQuestions[count]._id));
          }
        }
        dispatch(setIsPopUp(false));
        setTimeout(() => setIsNextDisabled(false), 500);
      };
    
       useEffect(() => {
         return () => {
           if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
           if (mathModuleTimeOut) clearTimeout(mathModuleTimeOut);
         };
       }, []);
  
      useEffect(() => {
        dispatch(changeCurrentModuleName("mathModule1"))
      // dispatch(openDialog("mathsInstructions"));
        dispatch(setIsModuleCompleted(false));
      },[])

  useEffect(() => {
    if (currentTime <= 0 && getItem("testType") !== "PREVIEW" && getItem("testType") !== "PREVIEW") {
      dispatch(setIsModuleCompleted(true));
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      mathModuleTimeOut = setTimeout(() => {
        dispatch(changeCurrentModuleName("mathModule2"));
        dispatch(setCurrentTime(2100));
      }, 7000);
      dispatch(setCount(""));
    }
  }, [currentTime]);

  return (
    <CommonTestModuleLayout
      heading={heading}
      timer={timer}
      exit={exit}
      textName={textName}
      next={() => next(nextClickHandler,isNextDisabled)}
      questionsList={mathModuleOneQuestions}
      moduleName={"mathsModuleOneAnswers"}
      userAnswers={mathsModuleOneAnswers}
          setStartTime={setStartTime}
          startTime={startTime}
          module={301}
          section={701}
      headerHeading={"Section 2, Module 1: Math Questions"}
    >
      {children}
    </CommonTestModuleLayout>
  );
};

export default MathModuleOne;
