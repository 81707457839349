
import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {setItem} from "../../utils/token"
import { startTestApi } from "../API/StartTest/startTestApi";
import { TestResult } from "../API/StartTest/userTestsType";

interface Answer {
    isActive: boolean;
    answerOption: string;
    isCorrectAnswer: boolean;
    correctAnswer: string;
    answerPlaceHolder: string;
  
}

interface ReadingModuleData{
    _id: string;
    isActive: boolean;
    description: string;
    question: string;
    processedQuestion:string;
    explanation: string;
    moduleId: string;
    sectionId: string;
    answers: Answer[];
    questionType : string;
    questionTypeId: string;
    more: any; // You might want to replace 'any' with a more specific type
    additionalProps: any; 
}
// Define the specific keys you want to allow in the payload
type SpentTimeFields = 'readingAndWritingModuleOneSpentTime' | 
                      'readingAndWritingModuleTwoSpentTime' | 
                      'mathModuleOneSpentTime' | 
                      'mathModuleTwoSpentTime';

// Define the action payload type
interface SetSpentTimePayload {
  field: SpentTimeFields;
  value: number;
}

interface TestData{
testid:string;
userTestId:string;
name:string;
isFreeTest:boolean;
testType:string;
isTestStarted:boolean
}

export interface StrikedOptionsData{
 questionId : string;
 strikedOptions : string[];
 markedForReview : boolean;
}

export interface HightLightText{
  selectedText:string;
  startIndex:number;
  bgColor : string;
  underline: string;
  contentType: string;
  isNotes: boolean;
  notes:string;
}

export interface HightLightTextData{
  questionId:string;
  hightLightText:HightLightText[];
}

export interface HighlighPopupPosition{
  top:string;
  left:string;
}

export interface StartedTestId{
  testId : string;
  userTestId : string;
  testName : string;
  createdDate : string;
}

export type FiltersType = {
  section: {
    readingWriting: boolean;
    math: boolean;
  };
  module: {
    module1: boolean;
    module2: boolean;
  };
  answerType: {
    correct: boolean;
    incorrect: boolean;
    omitted: boolean;
  };
};
interface FullTestState {
  isTestStarted: boolean;
  isDummy: boolean;
  currentTime: number;
  currentModuleName: string;
  readingModuleOneQuestions: ReadingModuleData[];
  readingModuleTwoQuestions: ReadingModuleData[]; // Question
  mathModuleOneQuestions: ReadingModuleData[];
  mathModuleTwoQuestions: ReadingModuleData[];
  count: number;
  isPopUp: boolean;
  testData: TestData[];
  testId: string;
  testName: string;
  userTestId: string;
  overallresult: any;
  completedTestData: any;
  filteredData: any;
  currentClickedQuestionId: string;
  isTestCompleted: false;
  readingAndWritingModuleOneSpentTime: number;
  readingAndWritingModuleTwoSpentTime: number;
  mathModuleOneSpentTime: number;
  mathModuleTwoSpentTime: number;
  sidebarCollapse: boolean;
  optionStrikerButton: boolean;
  strikedOptionsData: StrikedOptionsData[];
  reviewPageClicked: boolean;
  isHighlightNotes: boolean;
  highlightedContent: HightLightTextData[];
  highlightPopup: boolean;
  selectionBgColor: string;
  highlightPopupPosition: HighlighPopupPosition;
  previewTestData: any;
  openNotes: boolean;
  isHighlightPopupVisible: boolean;
  hasHighlightPopupBeenShown: boolean;
  activeModule: 301 | 302;
  activeSection: 701 | 702;
  startTime: Date | null;
  isModuleCompleted: boolean;
  startedTestId: StartedTestId;
  questionId: string;
  showWarning: boolean;
  parentStudentId: string;
  filters: FiltersType;
  isFullTestResultScreen: boolean;
  qId: string;
  minimisePanel: boolean;
  isTestInstructionsOpen: boolean;
  isResumeTest:boolean;
}


const initialState: FullTestState = {
  isTestStarted: false,
  showWarning: false,
  isDummy: true,
  currentTime: 1920,
  currentModuleName: "writingModule1",
  testData: [],
  completedTestData: {},
  testId: "",
  userTestId: "",
  testName: "",
  readingModuleOneQuestions: [],
  readingModuleTwoQuestions: [],
  mathModuleOneQuestions: [],
  mathModuleTwoQuestions: [],
  count: 0,
  isPopUp: false,
  overallresult: {},
  currentClickedQuestionId: "",
  isTestCompleted: false,
  readingAndWritingModuleOneSpentTime: 0,
  readingAndWritingModuleTwoSpentTime: 0,
  mathModuleOneSpentTime: 0,
  mathModuleTwoSpentTime: 0,
  sidebarCollapse: false,
  optionStrikerButton: false,
  strikedOptionsData: [],
  reviewPageClicked: false,
  isModuleCompleted: false,
  isHighlightNotes: false,
  highlightedContent: [],
  highlightPopup: false,
  selectionBgColor: "",
  highlightPopupPosition: {
    top: "",
    left: "",
  },
  previewTestData: {},
  openNotes: false,
  isHighlightPopupVisible: false,
  hasHighlightPopupBeenShown: false,
  activeModule: 301,
  activeSection: 702,
  startTime: null,
  startedTestId: {
    testId: "",
    userTestId: "",
    testName: "",
    createdDate: "",
  },
  questionId: "",
  parentStudentId: "",
  filteredData: [],
  filters: {
    section: {
      readingWriting: true,
      math: true,
    },
    module: {
      module1: true,
      module2: true,
    },
    answerType: {
      correct: true,
      incorrect: true,
      omitted: true,
    },
  },
  isFullTestResultScreen: false,
  qId: "",
  minimisePanel: false,
  isTestInstructionsOpen: false,
  isResumeTest:false,
};

const startFullTest = createSlice({
  name: "startFullTest",
  initialState,
  reducers: {
    setResetFilters: (state) => {
      state.filters = {
        section: {
          readingWriting: true,
          math: true,
        },
        module: {
          module1: true,
          module2: true,
        },
        answerType: {
          correct: true,
          incorrect: true,
          omitted: true,
        },
      };
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setIsTestStarted: (state, action) => {
      state.isTestStarted = action.payload;
    },
    setMinimisePanel: (state, action) => {
      state.minimisePanel = action.payload;
    },
    setIsResumeTest: (state, action) => {
      state.isResumeTest = action.payload;
    },
    setIsFullTestResultScreen: (state, action) => {
      state.isFullTestResultScreen = action.payload;
    },
    setShowWarning: (state, action) => {
      state.showWarning = action.payload;
    },
    setQuestionId: (state, action) => {
      state.questionId = action.payload;
    },
    setIsModuleCompleted: (state, action) => {
      state.isModuleCompleted = action.payload;
    },
    setActiveModule: (state, action) => {
      state.activeModule = action.payload;
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setQuestionStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    setIsSidebarCollapse: (state, action) => {
      state.sidebarCollapse = action.payload;
    },
    setOptionStrikerButton: (state, action) => {
      state.optionStrikerButton = action.payload;
    },
    setStrikedOptionsData: (state, action) => {
      state.strikedOptionsData = action.payload;
    },
    setIsHighlightNotes: (state, action) => {
      state.isHighlightNotes = action.payload;
    },
    setHighlightedContent: (state, action) => {
      state.highlightedContent = action.payload;
    },
    setHighlightPopup: (state, action) => {
      state.highlightPopup = action.payload;
    },
    setSelectionBgColor: (state, action) => {
      state.selectionBgColor = action.payload;
    },
    setHighlightPopupPosition: (state, action) => {
      state.highlightPopupPosition = action.payload;
    },
    setIsHighlightPopupVisible: (state, action) => {
      state.isHighlightPopupVisible = action.payload;
    },
    setHasHighlightPopupBeenShown: (state, action) => {
      state.hasHighlightPopupBeenShown = action.payload;
    },
    isDummyRender: (state, action) => {
      state.isDummy = action.payload;
    },
    changeCurrentModuleName: (state, action) => {
      state.currentModuleName = action.payload;
      setItem("currentModuleName", action.payload);
    },
    setSpentTime: (state, action: PayloadAction<SetSpentTimePayload>) => {
      const { field, value } = action.payload;
      if (state.hasOwnProperty(field)) {
        state[field] = value;
      }
    },
    setTestId: (state, action) => {
      state.testId = action.payload;
    },
    setTestName: (state, action) => {
      state.testName = action.payload;
    },
    setUserTestId: (state, action) => {
      state.userTestId = action.payload;
    },
    setCurrentClickedQuestion: (state, action) => {
      state.currentClickedQuestionId = action.payload;
    },
    setReadingModuleOneQuestions: (state, action) => {
      state.readingModuleOneQuestions = action.payload;
    },
    setReadingModuleTwoQuestions: (state, action) => {
      state.readingModuleTwoQuestions = action.payload;
    },
    setMathModuleOneQuestions: (state, action) => {
      state.mathModuleOneQuestions = action.payload;
    },
    setMathModuleTwoQuestions: (state, action) => {
      state.mathModuleTwoQuestions = action.payload;
    },
    setReviewPageClicked: (state, action) => {
      state.reviewPageClicked = action.payload;
    },
    setCount: (state, action) => {
      if (action.payload === "Increment") {
        state.count++;
      } else if (action.payload === "decrement") {
        state.count--;
      } else if (action.payload) {
        state.count = action.payload;
      } else {
        state.count = 0;
      }
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setIsPopUp: (state, action) => {
      state.isPopUp = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setOverallResultSlice: (state, action) => {
      state.overallresult = action.payload;
    },
    setTestCompleted: (state, action) => {
      state.isTestCompleted = action.payload;
    },
    setParentStudentId: (state, action) => {
      state.parentStudentId = action.payload;
    },
    setQid: (state, action) => {
      state.qId = action.payload;
    },
    handleSelectedText: (state, action) => {
      const { selectedText, parentId, questionId, startIndex, top, left } =
        action.payload;
      const existingQuestionIndex = state.highlightedContent?.findIndex(
        (item) => item.questionId === questionId
      );

      if (state.selectionBgColor === "") {
        state.selectionBgColor = "lightBlue";
      }

      if (existingQuestionIndex !== -1) {
        let questionHighlights = [
          ...state.highlightedContent[existingQuestionIndex].hightLightText,
        ];
        let endIndex = startIndex + selectedText.length;
        let isOverLapping = questionHighlights.some((highlight) => {
          const highlightStart = highlight.startIndex;
          const highlightEnd =
            highlight.startIndex + highlight.selectedText.length;
          return (
            startIndex < highlightEnd &&
            endIndex > highlightStart &&
            parentId === highlight.contentType
          ); // Check for overlap
        });

        if (!isOverLapping) {
          const selectedTextIndex = questionHighlights.findIndex(
            (item) =>
              item.selectedText === selectedText &&
              item.startIndex === startIndex
          );

          if (selectedTextIndex === -1) {
            questionHighlights.push({
              selectedText,
              startIndex,
              bgColor: state.selectionBgColor,
              underline: "none",
              contentType: parentId || "",
              isNotes: false,
              notes: "",
            });
            state.highlightedContent[existingQuestionIndex].hightLightText =
              questionHighlights;
            state.highlightPopup = true;
            state.highlightPopupPosition = { top, left };
          }
        }
      } else {
        state.highlightedContent.push({
          questionId,
          hightLightText: [
            {
              selectedText,
              startIndex,
              bgColor: state.selectionBgColor,
              underline: "none",
              contentType: parentId || "",
              isNotes: false,
              notes: "",
            },
          ],
        });
        state.highlightPopup = true;
        state.highlightPopupPosition = { top, left };
      }
    },
    handleDeleteSelectedSentence: (state, action) => {
      const { questionId, selectedText, startIndex } = action.payload;
      let existingQuestionIndex = state.highlightedContent.findIndex(
        (item) => item.questionId === questionId
      );

      if (existingQuestionIndex !== -1) {
        let selectedIndex = state.highlightedContent[
          existingQuestionIndex
        ].hightLightText.findIndex(
          (item) =>
            item.selectedText === selectedText && item.startIndex === startIndex
        );

        if (selectedIndex !== -1) {
          state.highlightedContent[existingQuestionIndex].hightLightText.splice(
            selectedIndex,
            1
          );
          state.highlightPopup = false;
        }
      }
    },
    setOpenNotes: (state, action) => {
      state.openNotes = action.payload;
    },
    setStartedTestId: (state, action) => {
      const { testId, testName, createdDate, userTestId } = action.payload;
      state.startedTestId.testId = testId;
      state.startedTestId.testName = testName;
      state.startedTestId.userTestId = userTestId;
      state.startedTestId.createdDate = createdDate;
    },
    setIsTestInstructionsOpen: (state, action) => {
      state.isTestInstructionsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      startTestApi.endpoints.getAllMockTests.matchFulfilled,
      (
        state: FullTestState,
        action: PayloadAction<{ data: { testData: TestData[] } }>
      ) => {
        const data = action.payload?.data?.testData;
        state.testData = [...data];
      }
    );
    builder.addMatcher(
      startTestApi.endpoints.getProTests.matchFulfilled,
      (
        state: FullTestState,
        action: PayloadAction<{ data: { testData: TestData[] } }>
      ) => {
        const newTestData = action.payload?.data?.testData || [];
        const combinedTestData = [...state.testData, ...newTestData];
        const uniqueTests = combinedTestData.filter((item, index, array) => {
          return (
            array.findIndex((innerItem) => innerItem.testid === item.testid) ===
            index
          );
        });
        state.testData = uniqueTests;
      }
    );
    builder.addMatcher(
      startTestApi.endpoints.getUserResultsById.matchFulfilled,
      (state: FullTestState, action: any) => {
        state.completedTestData = action.payload?.data;
        state.qId =
          action.payload?.data?.additionalProps?.overallresult?.allQuestionsTableData[0]?._id;
      }
    );
    builder.addMatcher(
      startTestApi.endpoints.getPreviewTest.matchFulfilled,
      (state: FullTestState, action: PayloadAction<any>) => {
        state.previewTestData = action.payload?.data?.testData || [];
      }
    );
  },
});

export const {
  setReviewPageClicked,
  setIsSidebarCollapse,
  setOptionStrikerButton,
  setStrikedOptionsData,
  setIsHighlightNotes,
  setHighlightedContent,
  handleSelectedText,
  handleDeleteSelectedSentence,
  setHighlightPopup,
  setHighlightPopupPosition,
  setOpenNotes,
  setIsHighlightPopupVisible,
  setSelectionBgColor,
  isDummyRender,
  setHasHighlightPopupBeenShown,
  changeCurrentModuleName,
  setSpentTime,
  setTestId,
  setCurrentClickedQuestion,
  setTestCompleted,
  setUserTestId,
  setTestName,
  setReadingModuleOneQuestions,
  setReadingModuleTwoQuestions,
  setMathModuleOneQuestions,
  setMathModuleTwoQuestions,
  setCount,
  setCurrentTime,
  setIsPopUp,
  setOverallResultSlice,
  setIsModuleCompleted,
  setActiveModule,
  setActiveSection,
  setQuestionStartTime,
  setStartedTestId,
  setQuestionId,
  setShowWarning,
  setParentStudentId,
  setFilters,
  setIsFullTestResultScreen,
  setQid,
  setMinimisePanel,
  setIsTestStarted,
  setFilteredData,
  setResetFilters,
  setIsTestInstructionsOpen,
  setIsResumeTest,
} = startFullTest.actions;
export default startFullTest.reducer;