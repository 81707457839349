import { useEffect, useState } from "react";
import { getItem, setItem } from "../../utils/token";
import { RiNewspaperLine,RiDeleteBinLine } from "react-icons/ri";
import AuthButton from "../LogInPage/LoginButton";
import { TestCards, DashboardWidgets } from "./DashboardCards";
import { IoIosPlay } from "react-icons/io";
import PracticeTestIcon from "../../assets/Images/PracticeClock.svg";
import { useLazyGetStartTestDataV1Query, useLazyRetrieveTestQuery, useResumeTestQuery } from "../../redux/API/StartTest/startTestApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeCurrentModuleName, setCount, setCurrentTime,setFilters, setHasHighlightPopupBeenShown, setHighlightedContent,setIsModuleCompleted,setIsPopUp, setIsResumeTest, setIsTestInstructionsOpen, setIsTestStarted, setMathModuleOneQuestions, setMathModuleTwoQuestions, setQid, setReadingModuleOneQuestions, setReadingModuleTwoQuestions, setResetFilters, setReviewPageClicked, setStartedTestId, setStrikedOptionsData, setTestId, setTestName, setUserTestId, StartedTestId } from "../../redux/Slices/startFullTestSlice";
import { RootState } from "../../redux/Store/store";
import { clearAnswers, retrieveMathsModuleOneAnswers, retrieveMathsModuleTwoAnswers, retrieveReadingModuleOneAnswers, retrieveReadingModuleTwoAnswers, setCurrentQuestion, setCurrentQuestionIndex } from "../../redux/Slices/resultSlice";
import { openDialog } from "../../redux/Slices/dialogSlice";
import { LuEye } from "react-icons/lu";
import FullTest from "../FullTest";
import TestInstructions from "../../components/Exam/TestInstructions";
import useContactUsIcon from "../../components/customHook/useContactUsIcon";

const DashboardV1 = () => {
  const userName = getItem("userName");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDisable,setStartDisable] = useState(false);

  const {data,isSuccess,isError,refetch} = useResumeTestQuery({},{refetchOnMountOrArgChange:true});
  const [retrieveTest] = useLazyRetrieveTestQuery();
  const [getStartTestDataV1] = useLazyGetStartTestDataV1Query();

  const { startedTestId , isTestStarted, isTestInstructionsOpen } = useSelector((state: RootState) => ({
    startedTestId: state.root.startFullTest.startedTestId,
    isTestStarted: state.root.startFullTest.isTestStarted,
    isTestInstructionsOpen: state.root.startFullTest.isTestInstructionsOpen,
    previewTestData: state.root.startFullTest.previewTestData
  }))


  const resumeTest = async (item: StartedTestId) => {

    try {
      if (item.testId) {
        const response = await retrieveTest(item.testId);
        if(response.isSuccess){
        let moduleName =
          response.data?.data?.additionalProps?.currentModuleName;
        let count = response.data?.data?.additionalProps?.count;
        localStorage.setItem("currentTestName", item.testName);
        dispatch(setTestId(item.testId));
        dispatch(setTestName(item.testName));
        let strikedAndMarkedData = response.data?.data?.additionalProps?.strikedAndMarkedData;
        let highlightedContent = response.data?.data?.additionalProps?.highlightedContent;
        let time = response.data?.data?.additionalProps?.currentTime;
        let reviewPage = response.data?.data?.additionalProps?.reviewPageClicked;
        dispatch(setStrikedOptionsData(strikedAndMarkedData));
        dispatch(setHighlightedContent(highlightedContent));
        dispatch(setReviewPageClicked(reviewPage));
        if (response.data.data) {
          let questions = response.data.data.questionData;
          let answersData = response.data.data.answerData;

          let readingQuestions = questions[`ReadingAndWriting`];
          let mathsQuestions = questions[`Mathematics`];

          let mathAnswers = answersData[`Mathematics`];
          let readingAnswers = answersData[`ReadingAndWriting`];
          dispatch(setTestId(item.testId));
          dispatch(setUserTestId(response.data.data.userTestId));

           const readingModuleOneQuestions = addQuestionId(
             readingQuestions[`Module1`]
           );
           const readingModuleTwoQuestions = addQuestionId(
             readingQuestions[`Module2`]
           );
           const mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
           const mathsModuleTwoQuestions = addQuestionId(mathsQuestions[`Module2`]);

          dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
          dispatch(setReadingModuleTwoQuestions(readingModuleTwoQuestions));
          dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
          dispatch(setMathModuleTwoQuestions(mathsModuleTwoQuestions));
          dispatch(retrieveMathsModuleOneAnswers(mathAnswers[`Module1`]));
          dispatch(retrieveMathsModuleTwoAnswers(mathAnswers[`Module2`]));
          dispatch(retrieveReadingModuleOneAnswers(readingAnswers[`Module1`]));
          dispatch(retrieveReadingModuleTwoAnswers(readingAnswers[`Module2`]));
          dispatch(changeCurrentModuleName(moduleName));
          dispatch(setCount(count));
          dispatch(setCurrentTime(time));
          dispatch(setIsTestStarted(true));
         }
        }
        setStartDisable(false);
      }
    } catch (e) {
      setStartDisable(false);
      console.error(e, "Resume Test");
    }
  };

  const handleResumeTest = () => {
    setStartDisable(true);
    dispatch(setHasHighlightPopupBeenShown(false));
    resumeTest(startedTestId)
    dispatch(setReviewPageClicked(false));
    dispatch(setIsModuleCompleted(false));
    dispatch(setIsPopUp(false));
    setItem("testType", "FULL");
    dispatch(setIsResumeTest(true))
  }

  const openDiscard = () => {
    dispatch(openDialog("discardInfo"));
  }

  const calculateExactAnswer = (options: any, answerValue: any) => {
    if (options.length > 0) {
      let correctOption = options.find((item: any) => item.isCorrectAnswer);
      return correctOption.answerOption;
    } else {
      return answerValue;
    }
  };

  const addQuestionId = (questions: any) => {
    return questions.map((item: any, index: any) => ({
      ...item,
      qIdUi: index + 1,
      exactAnswer: calculateExactAnswer(item.answers, item.answerValue),
    }));
  };

  const startTest = async (isPreview: boolean) => {
    try {
      let response = await getStartTestDataV1(isPreview);
      if (response.isSuccess) {
        if (response.data.data) {
          localStorage.setItem("currentTestName", response.data.data.testData.name);
          dispatch(setTestId(response.data.data.testData.testId));
          dispatch(setTestName(response.data.data.testData.name));

          let questions = response.data.data.testData.questions;
          let readingQuestions = questions[`ReadingAndWriting`];
          let mathsQuestions = questions[`Mathematics`];
          let readingModuleOneQuestions = [];
          let readingModuleTwoQuestions = [];
          let mathsModuleOneQuestions = [];
          let mathsModuleTwoQuestions = [];

         if (response.data.data.testData.testType === "PREVIEW") {
           setItem("testType", "PREVIEW");
           readingModuleOneQuestions = addQuestionId(
             readingQuestions[`Module1`]
           );
           mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
           dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
           dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
         } else {
           readingModuleOneQuestions = addQuestionId(
             readingQuestions[`Module1`]
           );
           readingModuleTwoQuestions = addQuestionId(
             readingQuestions[`Module2`]
           );
           mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
           mathsModuleTwoQuestions = addQuestionId(mathsQuestions[`Module2`]);
           dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
           dispatch(setReadingModuleTwoQuestions(readingModuleTwoQuestions));
           dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
           dispatch(setMathModuleTwoQuestions(mathsModuleTwoQuestions));
         }
          dispatch(setUserTestId(response.data.data.testData.userTestId));
        }

        dispatch(changeCurrentModuleName("writingModule1"));
        dispatch(setIsTestInstructionsOpen(true));
        setStartDisable(false);
      } else {
        setStartDisable(false);
        console.error("Error:", response.error);
      }
    } catch (e) {
      setStartDisable(false);
      console.error("Error:", e);
    }
  };

  const handleStartTest = (isPreview: boolean) => {
    dispatch(setCount(""));
    setStartDisable(true);
    dispatch(setHasHighlightPopupBeenShown(false));
    if (isPreview){
      dispatch(setStrikedOptionsData([]));
      startTest(true);
      setItem("testType", "PREVIEW");
      dispatch(setCurrentTime(0));
    } else {
      setItem("testType", "FULL");
      startTest(false);
      dispatch(setCurrentTime(1920));
    }
    dispatch(clearAnswers());
    dispatch(setReviewPageClicked(false));
    dispatch(setIsModuleCompleted(false));
    dispatch(setIsPopUp(false));
    dispatch(setIsResumeTest(false));
  }

  const formatDate = (dateString : string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}.${month}.${year}`;
  };

  const handleReportsBtn = () =>{
    navigate("/practices/results")
  }

  const isResumeEnabled = startedTestId.testId !== "";
  useEffect(() => {
   if(isSuccess && data){
    let payload = {
      testId : data.testId ?? "",
      testName: data.testname ?? "",
      createdDate: data.createdDate ?? "",
      userTestId : data?.userTestId ?? "",
    }
    dispatch(setStartedTestId(payload));
   }
   if(isError){
    let payload = {
      testId : "",
      testName: "",
      createdDate: "",
      userTestId : "",
    }
    dispatch(setStartedTestId(payload));
   }
  },[data, isSuccess, isError, dispatch]);

  useEffect(()=>{
   localStorage.removeItem('route')
  },[])

  useEffect(() => {
    dispatch(setQid(""));
    dispatch(setCurrentQuestion(""));
    dispatch(setCurrentQuestionIndex(""));
    dispatch(setResetFilters());

    if (!isTestStarted) {
      refetch();
    }
  }, [isTestStarted]);
  const userRole = getItem("userRole");
  return (
    <>
    { isTestStarted ? <FullTest /> : isTestInstructionsOpen ? <TestInstructions /> : 
      <div>
        {userRole === "STUDENT" ? (
          <div className="w-[70%] mx-auto flex flex-col gap-12 my-12">
            <div className="w-full flex justify-between items-center">
              <div className="font-gully text-[40px] font-bold flex gap-6 p-3 shadow-test-card rounded-3xl">
              {isResumeEnabled ? (
                <div className="flex gap-6">
                  <AuthButton
                    text="Resume Test"
                    onClick={handleResumeTest}
                    disabled={startDisable}
                    className="font-gully text-lg"
                    icon={<IoIosPlay  size={24}/>}
                    iconPosition="left"
                  />
                  <button
                    onClick={openDiscard}
                    className={`flex items-center justify-center py-[14px] px-[34px] rounded-xl text-lg border border-appPrimaryColor font-bold transition duration-200  text-appPrimaryColor bg-white hover:text-white  hover:bg-appPrimaryColor }`}
                  >
                    <span className="mr-2 flex items-center">
                      <RiDeleteBinLine />
                    </span>
                    Discard
                  </button>
                </div>
            ) : (
              <AuthButton
                text="Start Test"
                onClick={() => handleStartTest(false)}
                disabled={startDisable}
                className="font-gully text-lg"
                icon={<IoIosPlay size={24}/>}
                iconPosition="left"
              />)}
                <div className="h-14 w-[2px] bg-[#E1E1E1]"></div>
                <AuthButton
                  text="Preview Test"
                  icon={<LuEye />}
                  iconPosition="left"
                  onClick={() => handleStartTest(true)}
                  className="text-lg bg-white !text-appPrimaryColor border border-appPrimaryColor hover:text-appPrimaryColor"
                />
              </div>
              <div className="p-3 shadow-test-card rounded-3xl">
                <AuthButton
                  text="Reports"
                  icon={<RiNewspaperLine />}
                  iconPosition="left"
                  onClick={handleReportsBtn}
                  className="text-lg bg-white !text-appPrimaryColor border border-appPrimaryColor"
                />
              </div>
            </div>

            <div className="w-full">
              <DashboardWidgets/>
            </div>

          </div>
        ) : (
          <div className="flex items-center justify-center overflow-hidden flex-col mt-80">
            <p className="text-3xl text-lightBlack">
              You are not authorized to access this page.
            </p>
          </div>
        )}
      </div>
    }
    </>
  );
};

export default DashboardV1;

