import { getItem } from "./utils/token";

let be_url = "https://staging.api.satprep.fullmarks.io/api";
let cloudUrl = "https://ictservice.cambri.ai/api/";
let googleAuthUrl = "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=236294637564-cs746337sch63768tuhoreha1210ore8.apps.googleusercontent.com&redirect_uri=https://staging.api.satprep.fullmarks.io/api/auth/google/handle&scope=openid%20profile%20email&access_type=offline";
let appleAuthUrl = "https://staging.api.satprep.fullmarks.io/api/apple/auth/loginin";
let appleId = "stg.satprep.fullmarks.io";

const get_profile = () => {
  const host = window.location.host;
  if (host === "business.sat.cambri.ai") return "production";
  if (host === "satprep.fullmarks.io") return "production";
  if (host === "staging.sat.cambri.ai") return "stagging";
  if (host === "stg.satprep.fullmarks.io") return "stagging";
  if (host === "sat-web-test.web.app") return "test";
  if (host === "test.sat.cambri.ai") return "test";
  if (host === "test.satprep.fullmarks.io") return "test";
  if (host === "localhost:3000") return "dev";
  return "unknown";
};
const profile = get_profile();
switch (profile) {
  case "dev":
    be_url = "https://test.api.satprep.fullmarks.io/api";
    cloudUrl = "https://ictservice.cambri.ai/api/";

    break;
  case "test":
    be_url = "https://test.api.satprep.fullmarks.io/api";
    cloudUrl = "https://ictservice.cambri.ai/api/";
    appleAuthUrl = "https://test.api.satprep.fullmarks.io/api/apple/auth/loginin";
    appleId = "test.satprep.fullmarks.io";
    break;
  case "stagging":
    be_url = "https://staging.api.satprep.fullmarks.io/api";
    cloudUrl = "https://ictservice.cambri.ai/api/";
    googleAuthUrl = "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=236294637564-cs746337sch63768tuhoreha1210ore8.apps.googleusercontent.com&redirect_uri=https://staging.api.satprep.fullmarks.io/api/auth/google/handle&scope=openid%20profile%20email&access_type=offline";
    appleAuthUrl = "https://staging.api.satprep.fullmarks.io/api/apple/auth/loginin";
    appleId = "stg.satprep.fullmarks.io";
    break;
  case "production":
    be_url = "https://api.satprep.fullmarks.io/api";
    cloudUrl = "https://ictsystems.cambri.ai/api/";
    googleAuthUrl = "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=236294637564-cs746337sch63768tuhoreha1210ore8.apps.googleusercontent.com&redirect_uri=https://api.satprep.fullmarks.io/api/auth/google/handle&scope=openid%20profile%20email&access_type=offline";
    appleAuthUrl = "https://api.satprep.fullmarks.io/api/apple/auth/loginin";
    appleId = "satprep.fullmarks.io";
    break;

  default:
    console.warn(`Unknown profile: ${profile}`);
    break;
}

export const serviceUrl = be_url;
export const cloudServiceUrl = cloudUrl;
export const googleUrl = googleAuthUrl;
export const appleUrl = appleAuthUrl;
export const appleClientId = appleId;

export const cloudApplicationUrl =
  get_profile() === "production"
    ? "ielts-cms.web.app"
    : "cms-ielts-cambri.web.app";
    
export const genericHeaders = () => {
  if (getItem("token") != null) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getItem("token"),
    };
  }
  return {
    "Content-Type": "application/json",
  };
};
