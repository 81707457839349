import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import CustomButton from "./CustomButton";
import {
  setCount,
  setIsPopUp,
  setOptionStrikerButton,
  setReviewPageClicked,
  setStrikedOptionsData,
  StrikedOptionsData,
  setIsHighlightNotes,
  setHighlightedContent,
  HightLightTextData,
  setHighlightPopupPosition,
  setHighlightPopup,
  handleSelectedText,
  setOpenNotes,
  setIsHighlightPopupVisible,
  setActiveModule,
  setActiveSection,
  setQuestionStartTime,
  setQuestionId,
  setIsResumeTest,
} from "../../redux/Slices/startFullTestSlice";
import "katex/dist/katex.min.css";
import MathsModuleDirections from "./MathsModuleDirections";
import {
  retrieveMathsModuleOneAnswers,
  retrieveMathsModuleTwoAnswers,
  retrieveReadingModuleOneAnswers,
  retrieveReadingModuleTwoAnswers,
  setAnswers,
} from "../../redux/Slices/resultSlice";
import PopUp from "./PopUp";
import { getItem } from "../../utils/token";
import RenderText from "./RenderText";
import QuestionsOverView from "./QuestionsOverView";

import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import DirectionModal from "./DirectionModal";
import CrossOutIcon from "../../assets/Images/CrossOutToggle.svg";
import CrossOutOnIcon from "../../assets/Images/CrossOutToggleOn.svg";
import OptionACrossOut from "../../assets/Images/OptionACrossOut.svg";
import OptionBCrossOut from "../../assets/Images/OptionBCrossOut.svg";
import OptionCCrossOut from "../../assets/Images/OptionCCrossOut.svg";
import OptionDCrossOut from "../../assets/Images/OptionDCrossOut.svg";
import LineReader from "../Exam/LineReader";
import HighlightTextPopup, { NoteSection } from "./HighlightTextPopup";
import useSendTestActivity, {
  ActivityData,
} from "../customHook/useUserTestActivity";
import { RxBookmark, RxBookmarkFilled } from "react-icons/rx";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GetFile } from "../../utils";
import TestHeader from "./TestHeader";
import { PiWarningCircleFill } from "react-icons/pi";

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}
interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  processedQuestion: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionType: string;
  questionTypeId: string;
  more: any;
  additionalProps: any;
  imageDetails?: any;
}

interface CommonLayout {
  heading: () => React.ReactNode;
  timer: () => React.ReactNode;
  exit: () => React.ReactNode;
  children: () => React.ReactNode;
  textName: () => React.ReactNode;
  next: () => React.ReactNode;
  questionsList: ReadingModuleData[];
  moduleName: string;
  userAnswers: Answers[];
  setStartTime?: (time: any) => void;
  startTime?: any;
  module?: 301 | 302;
  section?: 701 | 702;
  headerHeading?: string;
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface FormAnswerSet {
  answerOption: string;
  answerPlaceHolder: string;
  isActive: boolean;
  isCorrectAnswer: boolean;
}

interface SelectedTextProp {
  selectedText: string;
  startIndex: number | null;
}

const CommonTestModuleLayout = ({
  heading,
  timer,
  exit,
  children,
  textName,
  next,
  questionsList,
  moduleName,
  userAnswers,
  setStartTime,
  startTime,
  module = 301,
  section = 702,
  headerHeading,
}: CommonLayout) => {
  const dispatch = useDispatch();
  const {
    count,
    isPopUp,
    sidebarCollapse,
    optionStrikerButton,
    strikedOptionsData,
    writingModuleOneAnswers,
    writingModuleTwoAnswers,
    mathsModuleOneAnswers,
    mathsModuleTwoAnswers,
    reviewPageClicked,
    currentTime,
    isModuleCompleted,
    isHighlightNotes,
    highlightedContent,
    highlightPopup,
    openNotes,
    userTestId,
    isResumeTest,
  } = useSelector((state: RootState) => ({
    count: state.root.startFullTest.count,
    isPopUp: state.root.startFullTest.isPopUp,
    sidebarCollapse: state.root.startFullTest.sidebarCollapse,
    optionStrikerButton: state.root.startFullTest.optionStrikerButton,
    strikedOptionsData: state.root.startFullTest.strikedOptionsData,
    writingModuleOneAnswers: state.root.resultsSlice.writingModuleOneAnswers,
    writingModuleTwoAnswers: state.root.resultsSlice.writingModuleTwoAnswers,
    mathsModuleOneAnswers: state.root.resultsSlice.mathsModuleOneAnswers,
    mathsModuleTwoAnswers: state.root.resultsSlice.mathsModuleTwoAnswers,
    reviewPageClicked: state.root.startFullTest.reviewPageClicked,
    currentTime: state.root.startFullTest.currentTime,
    isModuleCompleted: state.root.startFullTest.isModuleCompleted,
    isHighlightNotes: state.root.startFullTest.isHighlightNotes,
    highlightedContent: state.root.startFullTest.highlightedContent,
    highlightPopup: state.root.startFullTest.highlightPopup,
    testId: state.root.startFullTest.testId,
    openNotes: state.root.startFullTest.openNotes,
    isHighlightPopupVisible: state.root.startFullTest.isHighlightPopupVisible,
    hasHighlightPopupBeenShown:
      state.root.startFullTest.hasHighlightPopupBeenShown,
    userTestId: state.root.startFullTest.userTestId,
    isResumeTest:state.root.startFullTest.isResumeTest
  }));

  const [dialogModalOpen, setDialogModalOpen] = useState(true);
  const [lineReaderCard, setLineReaderCard] = useState(false);
  const [isBackDisabled, setIsBackDisabled] = useState(false);
  const userName = getItem("userName");
  const sendTestActivity = useSendTestActivity();
  const isPreview = getItem("testType") === "PREVIEW";
  const [error, setError] = useState("");
  const [answerPreview, setAnswerPreview] = useState("");

  const popupRef = useRef<HTMLParagraphElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isTextSelectingRef = useRef<boolean>(false);
  const selectedText = useRef<SelectedTextProp>({
    selectedText: "",
    startIndex: null,
  });
  let updatedHighlightContent: HightLightTextData[] = JSON.parse(
    JSON.stringify(highlightedContent ?? [])
  );
  const [isFocused, setIsFocused] = useState(false);
  const [url, setUrl] = useState("");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const memoizedUserAnswers = useMemo(() => userAnswers, [userAnswers]);

  const renderOptions = (answerOption: string) => {
    switch (answerOption) {
      case "Option A":
        return "A";
      case "Option B":
        return "B";
      case "Option C":
        return "C";
      case "Option D":
        return "D";
    }
  };

  const formAnswerSet = (
    answerItem: FormAnswerSet | string,
    uniqueQuestionId: string,
    questionType: string
  ) => {
    const endTime = new Date();
    if (!isPreview) {
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module,
        section,
        fromQuestion: count + 1,
        toQuestion: count + 1,
        event: 504,
        questionId: questionsList[count]._id,
      };
      dispatch(setQuestionId(questionsList[count]._id));
      sendTestActivity(activityData);
    }

    if (questionType === "MultipleChoice" && typeof answerItem === "object") {
      let tempAnswerObj = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem?.answerPlaceHolder,
          userOption: answerItem?.answerOption,
        },
      };
      dispatch(setAnswers(tempAnswerObj));

      //removal of the option from strikedoutoptions array.
      let strikedQuestionsData = strikedOptionsData?.map((item) => ({
        ...item,
        strikedOptions: [...item.strikedOptions],
      }));

      const questionIndex = strikedQuestionsData?.findIndex(
        (item) => item.questionId === uniqueQuestionId
      );
      if (questionIndex !== -1) {
        const optionIndex = strikedQuestionsData?.[
          questionIndex
        ]?.strikedOptions?.findIndex((opt) => opt === answerItem?.answerOption);
        if (optionIndex !== -1) {
          strikedQuestionsData?.[questionIndex]?.strikedOptions?.splice(
            optionIndex,
            1
          );
          dispatch(setStrikedOptionsData(strikedQuestionsData));
        }
      }
    }
    if (questionType === "ShortForm" && typeof answerItem === "string") {
      let tempAnswerObj2 = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem,
          userOption: "",
        },
      };
      dispatch(setAnswers(tempAnswerObj2));
    }
  };

  const renderInputFieldValue = (qItem: any) => {
    let matchedQuestion = memoizedUserAnswers.find(
      (item) => item.qId === qItem?.["_id"]
    );
    return matchedQuestion?.userAnswer ? matchedQuestion?.userAnswer : "";
  };

  const getDescriptionWidth = () => {
    if (getNoteSectionOpen() && !openNotes) {
      return "w-[49%]";
    }

    if (openNotes && getNoteSectionOpen()) {
      return "w-[38%]";
    }
    return "w-1/2";
  };

  useEffect(() => {
    if (questionsList?.length) {
      dispatch(setQuestionId(questionsList?.[count]?._id));
    }
  }, [questionsList]);

    const abortControllerRef = useRef<AbortController | null>(null);
    const currentQuestionRef = useRef<number | null>(null);

    useEffect(() => {
      setUrl("");

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      currentQuestionRef.current = count;

      const imageDetailKeys = Object.keys(
        questionsList[count]?.imageDetails || {}
      );

      const image =
        imageDetailKeys.length > 0
          ? questionsList[count]?.imageDetails[imageDetailKeys[0]]
          : null;

      const fetchImage = async () => {
        try {
          const questionCountAtFetch = count;
          const res = await GetFile(image?.fileName, { signal });
          if (
            !signal.aborted &&
            questionCountAtFetch === currentQuestionRef.current &&
            res
          ) {
            setUrl(res);
          }
        } catch (error) {
          if (
            error instanceof Error &&
            error.name !== "AbortError" &&
            count === currentQuestionRef.current
          ) {
            console.error("Error fetching image:", error);
            setUrl("");
          }
        }
      };

      if (image && image?.fileName) {
        fetchImage();
      }

      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
          abortControllerRef.current = null;
        }
        currentQuestionRef.current = null;
      };
    }, [questionsList[count]?.imageDetails, count]);

  const getNoteSectionOpen = () => {
    const existingQuestion: HightLightTextData | undefined =
      updatedHighlightContent.find(
        (item) => item.questionId === questionsList?.[count]?._id
      );

    return (
      existingQuestion?.hightLightText.some((item) => item.isNotes) ?? false
    );
  };

  const questionDescription = () => {
    return (
      <>
        <div
          className={`${getDescriptionWidth()} flex font-minion text-base overflow-y-auto scroll4 h-[calc(100vh-184px)] mr-1`}
        >
          {questionsList &&
          questionsList.length > 0 &&
          questionsList[count].description !==
            "student produced response directions" ? (
            <p className="pr-6 py-8 text-[#272727]" id="description">
              {url && (
                <div className="mb-3 mx-auto">
                  <img
                    src={url}
                    alt="images"
                    className="w-[500px] h-[500px] mx-auto"
                  />
                </div>
              )}
              <p className={`${isHighlightNotes && 'highlight-cursor'}`}>
                <RenderText
                  text={questionsList[count].description}
                  imageDetails={questionsList[count]}
                  isOptions={false}
                  contentType={"description"}
                  highlightedContent={updatedHighlightContent}
                  questionId={questionsList[count]?._id}
                  handleHighlightedTextClick={handleHighlightedTextClick}
                />
              </p>
            </p>
          ) : (
            <div>
              <MathsModuleDirections />
            </div>
          )}
        </div>
        {getNoteSectionOpen() && (
          <div
            className={`${
              openNotes ? "w-[13%]" : "w-[1%]"
            } bg-lightGrey relative`}
          >
            <div
              className={`flex w-8 h-6 px-3 py-2 items-center bg-gray-500  absolute bottom-11 ${
                openNotes
                  ? "left-0 rounded-r-[30px]"
                  : "rounded-l-[30px] -left-8"
              }`}
            >
              <button onClick={() => dispatch(setOpenNotes(!openNotes))}>
                {openNotes ? (
                  <IoIosArrowForward className="w-5 h-5 text-white" />
                ) : (
                  <IoIosArrowBack className="w-5 h-5 text-white" />
                )}
              </button>
            </div>
            <NoteSection
              NoteOpen={openNotes}
              highlightedContent={updatedHighlightContent}
              questionId={questionsList[count]["_id"]}
              selectedSentence={selectedText.current.selectedText}
              handleSetNotes={handleSetNotes}
            />
          </div>
        )}
      </>
    );
  };

  const questionContent = () => {
    return (
      <div className="w-1/2 py-8 px-6 font-minion text-base overflow-y-auto scroll4 h-[calc(100vh-184px)]">
        {questionsList && questionsList.length > 0 && (
          <>
            <div className="flex dashed-border dashed-border-bottom bg-lightGrey pb-[3px]">
              <div className="flex items-center self-stretch gap-3">
                <p className="border w-8 px-3 h-9 pb-[3px] bg-black flex items-center justify-center text-white font-semibold">
                  {count + 1}
                </p>
                <div
                  className={`flex inter text-sm items-center gap-2 ${
                    isMarkedForReview(questionsList[count]["_id"])
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  <button
                    onClick={() =>
                      handleMarkForReview(questionsList[count]["_id"])
                    }
                  >
                    {getReviewIcon(questionsList[count]["_id"])}
                  </button>
                  {""}Mark for Review
                </div>
              </div>
              {questionsList[count].questionType !== "Short Answer" && (
                <button
                  onClick={() =>
                    dispatch(setOptionStrikerButton(!optionStrikerButton))
                  }
                  className="cursor-pointer ml-auto mr-2 relative group"
                >
                  <img
                    src={optionStrikerButton ? CrossOutOnIcon : CrossOutIcon}
                    alt="cross-out-icon"
                    className="w-8 h-8 relative"
                  />
                  {""}
                  <span className="fixed top-[86px] z-50 right-[78px] font-minion  mb-3 w-max px-3 py-2 text-white bg-gray-700 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none flex flex-col items-start">
                    <p className="text-[13px] leading-none">Cross out answer choices</p> 
                    <p className="text-[13px] leading-none">you think are wrong.</p>
                    <span className="absolute top-[80%] left-[90%] transform -translate-x-1/2 w-3 h-3 bg-gray-700 rotate-45"></span>
                  </span>
                </button>
              )}
            </div>
            <div>
              <p className="mt-6" id="question">
                <RenderText
                  text={questionsList[count].processedQuestion}
                  imageDetails={questionsList[count]}
                  isOptions={false}
                  contentType={"question"}
                  highlightedContent={updatedHighlightContent}
                  questionId={questionsList[count]._id}
                  handleHighlightedTextClick={handleHighlightedTextClick}
                />
              </p>
            </div>
          </>
        )}
        <div>
          {questionsList[count].answers.length > 0 ? (
            questionsList[count].answers?.map((answerItem: Answer, index) => {
              let matchedQuestion = memoizedUserAnswers.find(
                (item) => item.qId === questionsList[count]["_id"]
              );
              let selectedOption = questionsList[count].answers.find(
                (itm) => itm.answerOption === matchedQuestion?.userOption
              );
              let isSelected =
                answerItem.answerOption === selectedOption?.answerOption;
              let isStrikedOut = optionStrikerButton
                ? handleStrikedOut(
                    questionsList[count]["_id"],
                    answerItem.answerOption
                  )
                : false;

              return (
                <div
                  className="flex w-full gap-[12px]"
                  key={answerItem.answerOption}
                >
                  <div
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    className={` ${
                      isSelected && isFocused
                        ? "border-[#E59700] border-[3px] rounded-xl"
                        : isSelected && !isFocused
                        ? "border-[#324DC7] border-[3px] rounded-xl"
                        : "border-transparent"
                    } border gap-3 my-3 flex w-full`}
                  >
                    <button
                      className={`${
                        isSelected && !isFocused
                          ? "border-transparent"
                          : "border-lightBlack"
                      } border flex flex-1 rounded-lg relative z-0 px-1 py-2 cursor-pointer`}
                      onClick={() =>
                        formAnswerSet(
                          answerItem,
                          questionsList[count]["_id"],
                          "MultipleChoice"
                        )
                      }
                      key={answerItem.answerOption}
                      disabled={isStrikedOut}
                    >
                      {isStrikedOut && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-[120%] h-[3px] bg-black -mx-2" />
                        </div>
                      )}

                      <div
                        className={`w-6 h-6 text-[13px] flex-shrink-0 border-2 border-black mx-2 rounded-full flex justify-center items-center ${
                          isSelected
                            ? "bg-[#324DC7] text-white border-[3px] border-[#324DC7]"
                            : "text-black"
                        } ${isStrikedOut && "text-darkGrey border-darkGrey"}`}
                      >
                        {renderOptions(answerItem.answerOption)}
                      </div>
                      <div
                        className={`text-left ml-2 my-auto ${
                          isStrikedOut && "text-darkGrey"
                        }`}
                      >
                        <p>
                          <p>
                            <RenderText
                              text={answerItem.answerPlaceHolder}
                              imageDetails={questionsList[count]}
                              isOptions={true}
                              contentType={"option"}
                              highlightedContent={[]}
                              questionId={questionsList[count]._id}
                              handleHighlightedTextClick={
                                handleHighlightedTextClick
                              }
                            />
                          </p>
                        </p>
                      </div>
                    </button>
                  </div>
                  {optionStrikerButton && (
                    <button
                      className="cursor-pointer max-w-8 max-h-8 my-auto"
                      onClick={() =>
                        handleStrikeOutOption(
                          questionsList[count]["_id"],
                          answerItem.answerOption
                        )
                      }
                    >
                      {isStrikedOut ? (
                        <p className="text-base font-bold underline">Undo</p>
                      ) : (
                        <img
                          src={getCrossOutImage(answerItem.answerOption)}
                          alt="cross-out-icon"
                          className="max-w-8 max-h-8"
                        />
                      )}
                    </button>
                  )}
                </div>
              );
            })
          ) : (
            <div className="mt-6">
              <div className="relative w-36 bg-white">
                <div
                  className={`relative ${
                    error
                      ? "border-2 border-[#af0606]"
                      : "border border-gray-500"
                  } rounded-xl px-3 py-2`}
                >
                  <div className="relative">
                    <div className="w-28 absolute bottom-0 left-0 h-px bg-gray-500 transition-all duration-200" />
                    <input
                      type="text"
                      placeholder=""
                      className={`border-transparent w-28 p-2 mt-2 rounded-md focus:outline-none text-lg`}
                      value={renderInputFieldValue(questionsList[count])}
                      onChange={(e) =>
                        handleYourAnswer(e, questionsList[count]["_id"])
                      }
                    />
                  </div>
                </div>
              </div>
              {error && (
                <div className="flex items-center mt-2 mb-4 gap-2">
                  <PiWarningCircleFill size={20} className="text-[#af0606]" />
                  <p className="text-[#af0606] font-medium text-xl">{error}</p>
                </div>
              )}
              <div
                className={`flex items-center gap-2 ${
                  error ? "mt-4" : "mt-10"
                }`}
              >
                <p className="text-black font-semibold text-2xl tracking-wide flex items-center gap-3">
                  Answer Preview:{" "}
                  <div className="text-2xl ml-4 flex flex-col items-center">
                    {answerPreview?.includes("/") ? (
                      <>
                        <div>{answerPreview?.split("/")[0]}</div>
                        <div className="w-full h-0.5 bg-black my-0"></div>
                        <div>{answerPreview?.split("/")[1]}</div>
                      </>
                    ) : (
                      <p>{answerPreview}</p>
                    )}
                  </div>
                </p>
              </div>
            </div>
          )}
        </div>
        <div></div>
      </div>
    );
  };

  const handleStrikeOutOption = (questionsId: string, option: string) => {
    let strikedQuestionsData =
      strikedOptionsData?.map((item) => ({
        ...item,
        strikedOptions: [...(item.strikedOptions || [])],
      })) || [];

    const questionIndex = strikedQuestionsData.findIndex(
      (item) => item.questionId === questionsId
    );

    if (questionIndex !== -1) {
      const strikedOptions =
        strikedQuestionsData[questionIndex].strikedOptions || [];
      const optionIndex = strikedOptions.findIndex((opt) => opt === option);

      if (optionIndex !== -1) {
        strikedOptions.splice(optionIndex, 1);
      } else {
        strikedOptions.push(option);
      }
      strikedQuestionsData[questionIndex].strikedOptions = strikedOptions;
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    } else {
      const newStrikedQuestion: StrikedOptionsData = {
        questionId: questionsId,
        strikedOptions: [option],
        markedForReview: false,
      };
      strikedQuestionsData.push(newStrikedQuestion);
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    }

    // Handle removal of the option if it is selected as an answer
    let answers = getSelectedModuleAnswers(moduleName);
    let updatedAnswers = answers ? [...answers] : []; // Ensure answers is an array
    const answerIndex = updatedAnswers.findIndex(
      (item) => item.qId === questionsId
    );

    if (
      answerIndex !== -1 &&
      updatedAnswers[answerIndex].userOption === option
    ) {
      updatedAnswers.splice(answerIndex, 1);
      if (moduleName === "writingModuleOneAnswers") {
        dispatch(retrieveReadingModuleOneAnswers(updatedAnswers));
      } else if (moduleName === "writingModuleTwoAnswers") {
        dispatch(retrieveReadingModuleTwoAnswers(updatedAnswers));
      } else if (moduleName === "mathsModuleOneAnswers") {
        dispatch(retrieveMathsModuleOneAnswers(updatedAnswers));
      } else if (moduleName === "mathsModuleTwoAnswers") {
        dispatch(retrieveMathsModuleTwoAnswers(updatedAnswers));
      }
    }
  };

  const getSelectedModuleAnswers = (module: string): Answers[] => {
    if (module === "writingModuleOneAnswers") {
      return writingModuleOneAnswers;
    } else if (module === "writingModuleTwoAnswers") {
      return writingModuleTwoAnswers;
    } else if (module === "mathsModuleOneAnswers") {
      return mathsModuleOneAnswers;
    } else if (module === "mathsModuleTwoAnswers") {
      return mathsModuleTwoAnswers;
    }
    return [];
  };

  const handleStrikedOut = (questionsId: string, option: string) => {
    let strikedQuestionsData = strikedOptionsData?.map((item) => item) || [];
    const questionIndex = strikedQuestionsData.findIndex(
      (item) => item.questionId === questionsId
    );
    if (questionIndex === -1) {
      return false;
    }

    const strikedOptions =
      strikedQuestionsData[questionIndex].strikedOptions || [];
    const optionIndex = strikedOptions.findIndex((opt) => opt === option);
    if (optionIndex !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const getCrossOutImage = (optionItem: string) => {
    switch (optionItem) {
      case "Option A":
        return OptionACrossOut;
      case "Option B":
        return OptionBCrossOut;
      case "Option C":
        return OptionCCrossOut;
      case "Option D":
        return OptionDCrossOut;
    }
  };

  const handleMarkForReview = (questionsId: string) => {
    if (!isPreview) {
      const endTime = new Date();
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module,
        section,
        fromQuestion: count + 1,
        toQuestion: count + 1,
        event: 502,
        questionId: questionsList[count]._id,
      };
      dispatch(setQuestionId(questionsList[count]._id));
      sendTestActivity(activityData);
    }

    let strikedQuestionsData =
      strikedOptionsData?.map((item) => ({ ...item })) || [];

    const questionIndex = strikedQuestionsData?.findIndex(
      (item) => item.questionId === questionsId
    );
    if (questionIndex !== -1) {
      strikedQuestionsData[questionIndex].markedForReview =
        !strikedQuestionsData[questionIndex].markedForReview;
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    } else {
      const newStrikedQuestion: StrikedOptionsData = {
        questionId: questionsId,
        strikedOptions: [],
        markedForReview: true,
      };
      strikedQuestionsData.push(newStrikedQuestion);
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    }
  };

  const isMarkedForReview = (questionId: string) => {
    let ismarkedForReview = strikedOptionsData?.find(
      (option) => option.questionId === questionId
    )?.markedForReview;
    return ismarkedForReview;
  };

  const getReviewIcon = (questionId: string) => {
    const ismarkedForReview = isMarkedForReview(questionId);
    return ismarkedForReview ? (
      <RxBookmarkFilled
        size={26}
        className="fill-red-800 text-red-800 stroke-black stroke-[1]"
      />
    ) : (
      <RxBookmark size={26} className="text-gray-500" />
    );
  };

  const handleHighlightedTextClick = (
    highlightText: string,
    startIndex: number,
    e: React.MouseEvent
  ) => {
    if (isTextSelectingRef.current) {
      isTextSelectingRef.current = false;
      return;
    }
    selectedText.current.selectedText = highlightText;
    selectedText.current.startIndex = startIndex;
    const rect = e.currentTarget.getBoundingClientRect();
    dispatch(
      setHighlightPopupPosition({
        top: rect.top + window.scrollY,
        left: rect.left,
      })
    );
    dispatch(setHighlightPopup(true));
  };

  const handleTextSelection = () => {
    if (getIsWritingModule()) {
      const selection = window.getSelection();
  
      if (selection?.toString()) {
        const selectedTextItem = selection.toString();
        const parentElement = selection.anchorNode?.parentElement;
        const superParentElement = parentElement?.closest("#question, #description");
  
        const focusElement = selection.focusNode?.parentElement;
        const focusSuperParentElement = focusElement?.closest("#question, #description");

        if (
          !superParentElement ||
          !focusSuperParentElement ||
          superParentElement !== focusSuperParentElement
        ) {
          return;
        }
  
        const parentId = superParentElement?.id;
        const questionId = questionsList[count]._id;
        const fullText = superParentElement?.textContent ?? "";

        const range = selection.getRangeAt(0);
        const startOffset = range.startOffset;
        const containerText = range.startContainer.textContent;

        let containerIndexInFullText = fullText.indexOf(containerText ?? "");

        const startIndex = containerIndexInFullText + startOffset;

        if (startIndex !== -1) {
          const rect = range.getBoundingClientRect();
          const top = rect.top + window.scrollY;
          let left = rect.left;
          if (left > window.innerWidth - 250) {
            left = window.innerWidth - 250;
          }
          const payload = {
            selectedText: selectedTextItem,
            parentId,
            questionId,
            startIndex,
            top,
            left,
          };

          dispatch(handleSelectedText(payload));

          selectedText.current.selectedText = selectedTextItem;
          selectedText.current.startIndex = startIndex;
          isTextSelectingRef.current = true;
        }
      }
    }
  };
  

  const handleSetNotes = (highlightedData: HightLightTextData) => {
    dispatch(setHighlightedContent(highlightedData));
  };

  const handleLineReader = () => {
    setLineReaderCard(!lineReaderCard);
  };

  useEffect(() => {
    const value = renderInputFieldValue(questionsList?.[count]);
    if (value.trim() !== "") {
      validateYourAnswer(value);
    } else {
      setError("");
      validateYourAnswer("");
    }
  }, [questionsList[count]]);

  const validateYourAnswer = (value: string, id?: string) => {
    if (value.startsWith("-/") || value.startsWith("./")) {
      setError(
        "You've entered a decimal point or slash in the wrong position."
      );
    }

    // Step 2: Prevent starting the input with `/`
    if (value.startsWith("/")) {
      setError(
        "You've entered a decimal point or slash in the wrong position."
      );
    }

    // Step 6: Length validation for positive and negative answers.
    if (
      (value.startsWith("-") && value.length > 6) ||
      (!value.startsWith("-") && value.length > 5) ||
      value.slice(1).includes("-") // Disallow minus in any position other than the first
    ) {
      return;
    }

    // Step 3: Prevent multiple consecutive slashes or dots.
    if (/(\/{2,})|(\.{2,})/.test(value)) {
      setError(
        "You've entered a decimal point or slash in the wrong position."
      );
    }

    // Step 4: Ensure that slashes are always preceded by a number (slash cannot appear without a number before it).
    if (/\/[^0-9.-]/.test(value)) {
      setError(
        "You've entered a decimal point or slash in the wrong position."
      );
    }

    // Step 5: Validate number and decimal format (without fraction).
    const validNumberFormat = /^-?\d*\.?\d*(\/?-?\d*\.?\d*)*$/; // Allows numbers, decimals, and slashes for fractions.
    if (!validNumberFormat.test(value)) {
      return;
    }

    // Step 7: Validate fraction format (with a slash between numbers).
    const validFractionFormat = /^-?\d*\.?\d*\/-?\d*\.?\d*$/;
    if (value.includes("/") && !validFractionFormat.test(value)) {
      setError(
        "You've entered a decimal point or slash in the wrong position."
      );
    }

    if (
      !(
        value.startsWith("-/") ||
        value.startsWith("./") ||
        value.startsWith("/") ||
        /(\/{2,})|(\.{2,})/.test(value) ||
        /\/[^0-9.-]/.test(value) ||
        !validNumberFormat.test(value) ||
        (value.includes("/") && !validFractionFormat.test(value))
      )
    ) {
      setAnswerPreview(value);
      setError("");
    } else {
      setAnswerPreview("");
    }
    if (id) {
      formAnswerSet(value, id, "ShortForm");
    }
  };

  const handleYourAnswer = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = e.target.value;
    validateYourAnswer(value, id);
  };

  function renderAnswersOrInput() {
    return (
      <div>
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].answers.length > 0 ? (
          questionsList[count].answers.map((answerItem: Answer, index) => {
            let matchedQuestion = memoizedUserAnswers.find(
              (item) => item.qId === questionsList?.[count]?.["_id"]
            );
            let selectedOption = questionsList[count].answers.find(
              (itm) => itm.answerPlaceHolder === matchedQuestion?.userAnswer
            );
            let isSelected =
              answerItem.answerOption === selectedOption?.answerOption;
            let isStrikedOut = optionStrikerButton
              ? handleStrikedOut(
                  questionsList?.[count]?.["_id"],
                  answerItem.answerOption
                )
              : false;
            return (
              <div
                className="flex min-w-[600px] w-fit pb-[23px] gap-3"
                key={answerItem.answerOption}
              >
                <div
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  className={`${
                    isSelected && isFocused
                      ? "border-[#E59700] border-[3px] rounded-xl"
                      : isSelected && !isFocused
                      ? "border-[#324DC7] border-[3px] rounded-xl"
                      : "border-transparent"
                  } border gap-3 flex min-w-[600px] w-fit`}
                >
                  <button
                    className={`border ${
                      isSelected && !isFocused
                        ? "border-transparent"
                        : "border-lightBlack"
                    } text-base min-w-[600px] w-fit flex rounded-lg relative z-0 px-1 cursor-pointer`}
                    onClick={() =>
                      formAnswerSet(
                        answerItem,
                        questionsList?.[count]?.["_id"],
                        "MultipleChoice"
                      )
                    }
                    key={answerItem.answerOption}
                    disabled={isStrikedOut}
                  >
                    {isStrikedOut && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-[120%] h-[3px] bg-black -mx-2" />
                      </div>
                    )}

                    <div
                      className={`w-[25px] h-[25px] border-2 border-[#272727] m-2 mr-6 text-sm rounded-full font-semibold flex justify-center items-center ${
                        isSelected ? "bg-[#324DC7] text-white" : " text-black"
                      } ${isStrikedOut && "text-darkGrey border-darkGrey"}`}
                    >
                      <p>{renderOptions(answerItem.answerOption)}</p>
                    </div>
                    <p
                      className={`flex justify-center  items-center my-auto ${
                        isStrikedOut && "text-darkGrey"
                      }`}
                    >
                      <p>
                        <RenderText
                          text={answerItem.answerPlaceHolder}
                          imageDetails={questionsList[count]}
                          isOptions={true}
                          contentType={"option"}
                          highlightedContent={[]}
                          questionId={questionsList?.[count]?.["_id"]}
                          handleHighlightedTextClick={
                            handleHighlightedTextClick
                          }
                        />
                      </p>
                    </p>
                  </button>
                </div>
                {optionStrikerButton && (
                  <button
                    className="cursor-pointer max-w-8 max-h-8 my-auto"
                    onClick={() =>
                      handleStrikeOutOption(
                        questionsList?.[count]?.["_id"],
                        answerItem.answerOption
                      )
                    }
                  >
                    {isStrikedOut ? (
                      <p className="text-base font-bold underline">Undo</p>
                    ) : (
                      <img
                        src={getCrossOutImage(answerItem.answerOption)}
                        alt="cross-out-icon"
                        className="max-w-8 max-h-8"
                      />
                    )}
                  </button>
                )}
              </div>
            );
          })
        ) : (
          <div className="m-4 ml-4">
            {questionsList && questionsList.length > 0 && (
              <input
                type="text"
                placeholder="Your Answer.."
                className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                value={renderInputFieldValue(questionsList[count])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formAnswerSet(
                    e.target.value,
                    questionsList?.[count]?.["_id"],
                    "ShortForm"
                  )
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
  const renderingQuestions = () => {
    return (
      <div className="scroll4 px-11 text-lg leading-7 overflow-y-auto font-minion h-[calc(100vh-184px)]">
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].description ? (
          <div className="w-full flex text-[#272727]">
            {questionDescription()}
            <div
              style={{ height: `calc(100vh - 184px)` }}
              className="flex items-center"
            >
              <div className="w-[4px] bg-gray-500 h-full"></div>
            </div>
            {questionContent()}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col w-[696px] mx-auto px-12">
            {questionsList && questionsList.length > 0 && (
              <>
                <div className="flex w-full border-b-2 border-dashed border-b-lightBlack bg-lightGrey mt-10">
                  <div className="flex items-center self-stretch gap-6">
                    <p className="border w-30 px-3 h-10 bg-black flex items-center justify-center text-white font-semibold">
                      {count + 1}
                    </p>
                    <div
                      className={`flex items-center gap-3 ${
                        isMarkedForReview(questionsList?.[count]?.["_id"])
                          ? "font-semibold"
                          : ""
                      }`}
                    >
                      <button
                        onClick={() =>
                          handleMarkForReview(questionsList?.[count]?.["_id"])
                        }
                      >
                        {getReviewIcon(questionsList?.[count]?.["_id"])}
                      </button>
                      {""}Mark For Review
                    </div>
                  </div>
                  {questionsList[count].questionType !== "Short Answer" && (
                    <button
                      onClick={() =>
                        dispatch(setOptionStrikerButton(!optionStrikerButton))
                      }
                      className="cursor-pointer ml-auto mr-2 relative group"
                    >
                      <img
                        src={
                          optionStrikerButton ? CrossOutOnIcon : CrossOutIcon
                        }
                        alt="cross-out-icon"
                        className="w-8 h-8 relative"
                      />
                      <span className="absolute -top-10 z-50 right-0 font-minion  mb-3 w-max px-3 py-2 text-white bg-gray-700 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none flex flex-col items-start">
                        <p className="text-[13px] leading-none">Cross out answer choices</p> 
                        <p className="text-[13px] leading-none">you think are wrong.</p>
                        <span className="absolute top-[80%] left-[90%] transform -translate-x-1/2 w-3 h-3 bg-gray-700 rotate-45"></span>
                      </span>
                    </button>
                  )}
                </div>
                <div>
                  {url && Object.keys(questionsList[count]?.imageDetails).length > 0 && (
                    <img src={url} alt="desc" className="mt-6 mb-3 mx-auto" />
                  )}
                  <p className="mt-6 mb-[23px]">
                    <RenderText
                      text={questionsList[count].processedQuestion}
                      imageDetails={questionsList[count]}
                      contentType={"question"}
                      highlightedContent={updatedHighlightContent}
                      questionId={questionsList?.[count]?.["_id"]}
                      handleHighlightedTextClick={handleHighlightedTextClick}
                    />
                  </p>
                </div>
              </>
            )}
            {renderAnswersOrInput()}
          </div>
        )}
      </div>
    );
  };

  const handleOpenPopup = () => {
    dispatch(setIsPopUp(!isPopUp));
  };
  const closePopUp = useCallback(() => {
    if (isPopUp) dispatch(setIsPopUp(false));
  }, [isPopUp]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      const message = "Changes you made may not be saved.";
      e.returnValue = message; // Standard for most browsers
      return message; // Some browsers need this explicit return
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("mouseup", handleTextSelection);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("mouseup", handleTextSelection);
        setIsHighlightNotes(false);
      }
    };
  }, [isHighlightNotes, questionsList[count]?._id]);

  useEffect(() => {
    if (
      moduleName === "mathsModuleOneAnswers" ||
      moduleName === "mathsModuleTwoAnswers"
    ) {
      setIsHighlightNotes(false);
    }
  }, [moduleName, questionsList[count]?._id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        dispatch(setIsHighlightPopupVisible(false));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(setActiveModule(module));
    dispatch(setActiveSection(section));
    dispatch(setQuestionStartTime(startTime));
  }, [module, section]);
  useEffect(() => {
    if(isResumeTest){
      setDialogModalOpen(false);
      dispatch(setIsResumeTest(false));
    }
  },[])

  const handleDirectionModal = () => {
    setDialogModalOpen(!dialogModalOpen);
  };

  const renderTestContent = () => {
    if (count === questionsList.length || reviewPageClicked) {
      return (
        <div
          style={{ height: "calc(100vh - 184px)" }}
          className="w-full mx-auto overflow-auto scroll4"
        >
          <div className="flex flex-col text-[#333] gap-4">
            <div className=" text-[32px] text-center my-10">
              Check Your Work
            </div>
            <div className="flex flex-col gap-2 text-base mx-auto my-2 font-base text-center px-6">
              <p>
                On test day, you won't be able to move on to the next module
                until time expires.
              </p>
              <p>
                For these practice questions, you can click{" "}
                <span className="font-bold">Next</span> when you're ready to
                move on.
              </p>
            </div>
            {/* <div className="font-semibold mx-auto text-xl">
            {heading()}
          </div> */}
          </div>
          <QuestionsOverView
            questionsList={questionsList}
            userAnswers={userAnswers}
            count={count}
            navigation={false}
            heading={headerHeading ?? ""}
            strikedOutOptions={strikedOptionsData}
            setStartTime={setStartTime}
            module={module}
            section={section}
            startTime={startTime}
          />
        </div>
      );
    }

    if (!isPreview && (currentTime <= 0 || isModuleCompleted)) {
      return "";
    }
    if (isPreview && isModuleCompleted) {
      return "";
    }

    return renderingQuestions();
  };

  const getIsWritingModule = () => {
    if (
      moduleName === "writingModuleOneAnswers" ||
      moduleName === "writingModuleTwoAnswers"
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className="flex flex-col h-screen relative overflow-hidden">
      {lineReaderCard && (
        <LineReader handleLineReader={handleLineReader} module={moduleName} />
      )}

      {getIsWritingModule() && highlightPopup && (
        <HighlightTextPopup
          module={module}
          section={section}
          startTime={startTime}
          questionId={questionsList[count]._id}
          selectedText={selectedText.current.selectedText}
          startIndex={selectedText.current.startIndex}
        />
      )}

      <DirectionModal
        module={moduleName}
        onCloseHandler={handleDirectionModal}
        dialogOpen={dialogModalOpen}
      />
      <div className="fixed top-0 w-full z-10">
        <TestHeader
          headerHeading={headerHeading ?? ""}
          handleDirectionModal={handleDirectionModal}
          dialogModalOpen={dialogModalOpen}
          getIsWritingModule={getIsWritingModule}
          handleLineReader={handleLineReader}
          popupRef={popupRef}
        />
        <div className="bg-[#1B2264] flex justify-center items-center text-xs font-semibold text-white h-[26px] py-2 text-center mx-[45px] rounded-b-2xl">
          {isPreview ? "THIS IS A TEST PREVIEW " : "THIS IS A PRACTICE TEST"}
        </div>
      </div>
      <div
        style={{ marginTop: "104px" }}
        className="flex-grow overflow-hidden"
        ref={contentRef}
      >
        {renderTestContent()}
      </div>

      <div
        style={{
          width: sidebarCollapse ? "calc(100% - 224px)" : "100%",
        }}
        className="!h-[80px] bg-[#E6EDF8] fixed border-t-2 bottom-0 dashed-border dashed-border-top flex justify-between items-center px-11 z-0"
      >
        <div className="flex items-center justify-between w-[43%]">
          <div className="text-xl">{userName}</div>
        </div>
        <div className="min-w-[14%] flex justify-center">
          {(currentTime > 0 || isPreview) && !isModuleCompleted && (
            <div className="relative inline-block">
              <button
                className={` relative font-bold  whitespace-nowrap cursor-pointer text-base bg-black text-white ${
                  count === questionsList.length || reviewPageClicked
                    ? "hidden"
                    : "flex justify-center items-center"
                }  border rounded-md  border-[#e4e4e4] px-4 py-2`}
                onClick={() => handleOpenPopup()}
              >
                Question {count + 1} of {questionsList?.length}
                <span className="ml-2">
                  {isPopUp ? <FaChevronDown /> : <FaChevronUp />}
                </span>
              </button>
              <PopUp
                onClose={closePopUp}
                questionsList={questionsList}
                userAnswers={memoizedUserAnswers}
                heading={heading}
                headerHeading={headerHeading ?? ""}
                count={count}
                isPopUp={isPopUp}
                strikedOutOptions={strikedOptionsData}
                setStartTime={setStartTime}
                module={module}
                section={section}
                startTime={startTime}
              />
            </div>
          )}
        </div>
        <div className="flex w-[43%] justify-end items-center">
          {((count !== 0 && currentTime >= 0) ||
            (count !== 0 && isPreview) ||
            reviewPageClicked) && (
            <div>
              <CustomButton
                name="Back"
                onClickHandler={async () => {
                  setIsBackDisabled(true);
                  if (reviewPageClicked) {
                    let lastQuestion: number = questionsList.length - 1;
                    dispatch(setCount(lastQuestion));
                    dispatch(setQuestionId(questionsList[lastQuestion]?._id));
                  } else {
                    dispatch(setCount("decrement"));
                    dispatch(setQuestionId(questionsList[count]?._id));
                  }
                  const endTime = new Date();
                  dispatch(setReviewPageClicked(false));

                  if (setStartTime) {
                    setStartTime(endTime);
                  }
                  if (!isPreview) {
                    const activityData: ActivityData = {
                      testId: userTestId,
                      startTime,
                      endTime,
                      module,
                      section,
                      fromQuestion:
                        count === questionsList.length ? count : count + 1,
                      toQuestion: count,
                      event: 501,
                      questionId: questionsList[count]?._id,
                    };
                    sendTestActivity(activityData);
                  }
                  setTimeout(() => setIsBackDisabled(false), 500);
                }}
                disabled={isBackDisabled}
                font="font-bold transition duration-700 ease-in-out active:underline active:bg-secondaryColor active:opacity-50"
                width="text-sm"
                py="py-2 px-5"
                round="full"
              />
            </div>
          )}
          <div className="rounded-full ml-4">{next()}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonTestModuleLayout;
